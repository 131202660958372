import { ChangeEvent, CSSProperties, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import Fuse, { FuseResult } from 'fuse.js';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../../Colors';
import SearchIcon from '../../../icons/SearchIcon';
import { useTranslation } from 'react-i18next';
import {
    RootBox,
    SearchIconBox,
    SearchResultBox,
    SearchResultsWrapper,
    SearchWrapper,
    StyledSearchInput
} from './Style';
import { useAccountsStateValue } from '../../../contexts/AccountsContext';
import { useCrumbsStateValue } from 'nulia-ui';
import { ICustomerVM } from '../../../interfaces/views/ICustomerVM';

type Props = {
    containerStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    resultsStyle?: CSSProperties;
    backgroundColor?: string;
    searchWidth?: number;
    resultsWidth?: number;
};

const SearchAccounts: FC<Props> = ({ backgroundColor, searchWidth, resultsWidth }) => {
    const [allCustomers, setAllCustomers] = useState<ICustomerVM[]>([]);
    const [searchString, setSearchString] = useState<string>('');
    const searchRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [showResults, setShowResults] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [results, setResults] = useState<FuseResult<ICustomerVM>[]>([]);
    const { customersData } = useAccountsStateValue();
    const { startNewCrumbs } = useCrumbsStateValue();

    useEffect(() => {
        setAllCustomers(customersData || []);
    }, [customersData]);

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const fuse = new Fuse(allCustomers, {
            keys: [`name`]
        });
        setResults(fuse.search(value).slice(0, 5));
        setShowResults(value !== '');
        setSearchString(event.target.value);
    };

    const onSelectOption = (fuseResult: FuseResult<ICustomerVM>) => (event: MouseEvent) => {
        if (inputRef.current) {
            inputRef.current.value = fuseResult.item.name;
        }
        setResults([]);
        setSearchString('');
        const account = allCustomers.find((a) => a.name === fuseResult.item.name);
        if (account) {
            const pathname = `/partner/accounts/${account.id}`;
            startNewCrumbs(
                {
                    name: 'Accounts',
                    pathname
                },
                true
            );
            navigate(pathname);
        }
    };

    return (
        <RootBox style={{ width: searchWidth }} ref={searchRef}>
            <SearchWrapper style={{ backgroundColor }}>
                <SearchIconBox>
                    <SearchIcon large={true} color={Colors.textTertiary} />
                </SearchIconBox>
                <StyledSearchInput
                    style={{ backgroundColor }}
                    value={searchString}
                    onChange={onSearch}
                    placeholder={t('general.searchPlaceholder')}
                    onBlur={() => {
                        setTimeout(() => {
                            setShowResults(false);
                        }, 200);
                    }}
                    ref={inputRef}
                />
            </SearchWrapper>
            {results.length > 0 && inputRef.current && inputRef.current.value !== '' && showResults && (
                <SearchResultsWrapper style={{ width: resultsWidth }}>
                    {results.map((result, index) => (
                        <>
                            <SearchResultBox key={result.item.id + index} onClick={onSelectOption(result)} tabIndex={0}>
                                {result.item.name}
                            </SearchResultBox>
                        </>
                    ))}
                </SearchResultsWrapper>
            )}
        </RootBox>
    );
};

export default SearchAccounts;

export enum EApiQueryKey {
    // Accounts query service
    ACCOUNTS_FETCH_ALL = 'accounts.getAll',
    ACCOUNTS_DETAILS_FETCH = 'accounts.details.get',
    ACCOUNTS_FETCH_RESELLERS = 'accounts.getResellers',
    ACCOUNTS_FETCH_CUSTOMERS = 'accounts.getCustomers',
    RESOURCES_FETCH_ALL = 'resources.getAll',
    CUSTOMER_ADMINISTRATORS_FETCH = 'customers.administrators.getAll',
    CUSTOMER_SETTINGS_FETCH = 'customers.settings.getAll',

    // Partner query service
    PARTNER_FETCH_LICENSE_SUMMARY = 'partner.getLicenseSummary',

    // Folders query service
    FOLDER_LICENSE_SUMMARY_FETCH = 'folders.getFolderLicenseSummary',
    PARTNERS_FETCH_ALL = 'partners.getAll',
    PARTNER_USERS_FETCH_ALL = 'partner.users.getAll'
}

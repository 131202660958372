import { EUserRoleImplementation } from '../interfaces/enums/EUserRoleImplementation';
import { ERoute } from '../interfaces/enums/ERoute';
import { routes } from './routes';
import { EUserPermission } from '../interfaces/enums/EUserPermission';

export type IComplexRule = {
    role: EUserRoleImplementation;
    permission: EUserPermission[];
    operator?: 'AND' | 'OR';
};

export function isComplexRule(arg: any): arg is IComplexRule {
    return arg && arg.permission;
}

const userManagerPageRuleSet = [
    {
        role: EUserRoleImplementation.USER,
        permission: [EUserPermission.USER]
    },
    {
        role: EUserRoleImplementation.MANAGER,
        permission: [EUserPermission.USER]
    }
];

const insightsOrganizationRuleSet = [
    {
        role: EUserRoleImplementation.ADMIN,
        permission: [EUserPermission.TENANT_INSIGHTS]
    },
    {
        role: EUserRoleImplementation.MANAGER,
        permission: [EUserPermission.TENANT_INSIGHTS]
    }
];

const routeRules: {
    [key in ERoute]: {
        rules: (EUserRoleImplementation | IComplexRule)[];
        route: string;
    };
} = {
    [ERoute.SKILLS]: {
        rules: userManagerPageRuleSet,
        route: routes.SKILLS
    },
    [ERoute.SKILL]: {
        rules: userManagerPageRuleSet,
        route: routes.SKILL
    },
    [ERoute.OUTCOME]: {
        rules: userManagerPageRuleSet,
        route: routes.SKILL
    },
    [ERoute.HOME]: {
        rules: userManagerPageRuleSet,
        route: routes.HOME
    },
    [ERoute.ADMIN]: {
        rules: [
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [
                    EUserPermission.LICENSE_ASSIGNMENT,
                    EUserPermission.ROLE_ASSIGNMENT,
                    EUserPermission.TENANT_OUTCOME_ASSIGNMENT
                ],
                operator: 'OR'
            },
            {
                role: EUserRoleImplementation.MANAGER,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            }
        ],
        route: routes.ADMIN
    },
    [ERoute.ADMIN_LICENSES]: {
        rules: [
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.LICENSE_ASSIGNMENT]
            }
        ],
        route: routes.ADMIN_LICENSES
    },
    [ERoute.ADMIN_ROLES]: {
        rules: [
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.ROLE_ASSIGNMENT]
            }
        ],
        route: routes.ADMIN_ROLES
    },
    [ERoute.SETTINGS]: {
        rules: [
            ...userManagerPageRuleSet,
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.TENANT_PREFERENCES]
            }
        ],
        route: routes.SETTINGS
    },
    [ERoute.SETTINGS_PERSONAL]: {
        rules: userManagerPageRuleSet,
        route: routes.SETTINGS_PERSONAL
    },
    [ERoute.SETTINGS_ORGANIZATIONAL]: {
        rules: [
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.TENANT_PREFERENCES]
            }
        ],
        route: routes.SETTINGS_ORGANIZATIONAL
    },
    [ERoute.ADMIN_HISTORY]: {
        rules: [
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.LICENSE_ASSIGNMENT, EUserPermission.TENANT_OUTCOME_ASSIGNMENT],
                operator: 'OR'
            }
        ],
        route: routes.ADMIN_HISTORY
    },
    [ERoute.ADMIN_HISTORY_DETAILS]: {
        rules: [
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.LICENSE_ASSIGNMENT, EUserPermission.TENANT_OUTCOME_ASSIGNMENT],
                operator: 'OR'
            },
            {
                role: EUserRoleImplementation.MANAGER,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            }
        ],
        route: routes.ADMIN_HISTORY_DETAILS
    },
    [ERoute.ADMIN_OUTCOMES]: {
        rules: [
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            },
            {
                role: EUserRoleImplementation.MANAGER,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            }
        ],
        route: routes.ADMIN_OUTCOMES
    },
    [ERoute.TABS_OUTCOMES]: {
        rules: [
            ...userManagerPageRuleSet,
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            },
            {
                role: EUserRoleImplementation.MANAGER,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            }
        ],
        route: routes.TAB_OUTCOMES
    },
    [ERoute.USER_OUTCOMES]: {
        rules: userManagerPageRuleSet,
        route: routes.USER_OUTCOMES
    },
    [ERoute.OUTCOMES_HISTORY]: {
        rules: [
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            },
            {
                role: EUserRoleImplementation.MANAGER,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            }
        ],
        route: routes.HISTORY_OUTCOMES
    },
    [ERoute.FEEDBACK]: {
        rules: [
            EUserRoleImplementation.USER,
            EUserRoleImplementation.MANAGER,
            EUserRoleImplementation.CHAMPION,
            EUserRoleImplementation.ADMIN,
            EUserRoleImplementation.PARTNER,
            EUserRoleImplementation.INTERNAL
        ],
        route: routes.FEEDBACK
    },
    [ERoute.INSIGHTS]: {
        rules: [...userManagerPageRuleSet, ...insightsOrganizationRuleSet],
        route: routes.INSIGHTS
    },
    [ERoute.INSIGHTS_PERSONAL]: {
        rules: userManagerPageRuleSet,
        route: routes.INSIGHTS_PERSONAL
    },
    [ERoute.INSIGHTS_PERSONAL_BADGES]: {
        rules: userManagerPageRuleSet,
        route: routes.INSIGHTS_PERSONAL_BADGES
    },
    [ERoute.INSIGHTS_PERSONAL_ENGAGEMENT]: {
        rules: userManagerPageRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT
    },
    [ERoute.INSIGHTS_PERSONAL_OUTCOMES]: {
        rules: userManagerPageRuleSet,
        route: routes.INSIGHTS_PERSONAL_OUTCOMES
    },
    [ERoute.INSIGHTS_PERSONAL_SKILLS]: {
        rules: userManagerPageRuleSet,
        route: routes.INSIGHTS_PERSONAL_SKILLS
    },
    [ERoute.INSIGHTS_ORGANIZATION]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL
    },
    [ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT]: {
        rules: [EUserRoleImplementation.ADMIN],
        route: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT
    },
    [ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT_DETAILS]: {
        rules: [EUserRoleImplementation.ADMIN],
        route: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS
    },
    [ERoute.INSIGHTS_ORGANIZATION_SINGLE_BADGE]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_SINGLE_BADGE
    },
    [ERoute.INSIGHTS_ORGANIZATION_BADGES]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_BADGES
    },
    [ERoute.INSIGHTS_ORGANIZATION_ENGAGEMENT]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT
    },
    [ERoute.INSIGHTS_ORGANIZATION_OUTCOMES]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES
    },
    [ERoute.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES
    },
    [ERoute.INSIGHTS_ORGANIZATION_USERS]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_USERS
    },

    [ERoute.INSIGHTS_ORGANIZATION_USER_SKILLS]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS
    },
    [ERoute.INSIGHTS_ORGANIZATION_USER_OUTCOMES]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES
    },
    [ERoute.INSIGHTS_ORGANIZATION_USER_ENGAGEMENT]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT
    },
    [ERoute.INSIGHTS_ORGANIZATION_USER_BADGES]: {
        rules: insightsOrganizationRuleSet,
        route: routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES
    },
    [ERoute.SHOWCASE_HOME]: {
        rules: [EUserRoleImplementation.USER, EUserRoleImplementation.MANAGER],
        route: routes.SHOWCASE_HOME
    },
    [ERoute.ACHIEVEMENTS_PREVIEW]: {
        rules: [EUserRoleImplementation.USER, EUserRoleImplementation.MANAGER],
        route: routes.ACHIEVEMENTS_ALL_BY_STATE
    },
    [ERoute.ACHIEVEMENTS_DETAIL]: {
        rules: [EUserRoleImplementation.USER, EUserRoleImplementation.MANAGER],
        route: routes.ACHIEVEMENT_DETAIL
    },
    [ERoute.BADGES_ALL_BY_CATEGORY]: {
        rules: [EUserRoleImplementation.USER, EUserRoleImplementation.MANAGER],
        route: routes.BADGES_ALL_BY_CATEGORY
    },

    // [ERoute.INSIGHTS]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS,
    // },
    // [ERoute.INSIGHTS_PERSONAL]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_PERSONAL,
    // },
    // [ERoute.INSIGHTS_PERSONAL_BADGES]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_PERSONAL_BADGES,
    // },
    // [ERoute.INSIGHTS_PERSONAL_ENGAGEMENT]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT,
    // },
    // [ERoute.INSIGHTS_PERSONAL_OUTCOMES]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_PERSONAL_OUTCOMES,
    // },
    // [ERoute.INSIGHTS_PERSONAL_SKILLS]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_PERSONAL_SKILLS,
    // },
    // [ERoute.INSIGHTS_ORGANIZATION]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_ORGANIZATIONAL,
    // },
    // [ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT,
    // },
    // [ERoute.INSIGHTS_ORGANIZATION_ASSESSMENT_DETAILS]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT,
    // },
    // [ERoute.INSIGHTS_ORGANIZATION_BADGES]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_ORGANIZATIONAL_BADGES,
    // },
    // [ERoute.INSIGHTS_ORGANIZATION_ENGAGEMENT]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT,
    // },
    // [ERoute.INSIGHTS_ORGANIZATION_OUTCOMES]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES,
    // },
    // [ERoute.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES,
    // },
    // [ERoute.INSIGHTS_ORGANIZATION_USERS]: {
    //     rules: [
    //         EUserRoleImplementation.USER,
    //         EUserRoleImplementation.MANAGER,
    //         EUserRoleImplementation.CHAMPION,
    //         EUserRoleImplementation.ADMIN,
    //         EUserRoleImplementation.PARTNER,
    //         EUserRoleImplementation.INTERNAL,
    //     ],
    //     route: routes.INSIGHTS_ORGANIZATIONAL_USERS,
    // },

    // -- END --

    [ERoute.LINKEDIN]: {
        rules: userManagerPageRuleSet,
        route: routes.LINKEDIN
    },
    [ERoute.INSTRUCTIONS]: {
        rules: userManagerPageRuleSet,
        route: routes.INSTRUCTIONS
    },
    [ERoute.HOW_TOS_HOMEPAGE]: {
        rules: [EUserRoleImplementation.USER, EUserRoleImplementation.MANAGER, EUserRoleImplementation.INTERNAL],
        route: routes.HOW_TOS_HOMEPAGE
    },
    [ERoute.HOW_TO]: {
        rules: [EUserRoleImplementation.USER, EUserRoleImplementation.MANAGER, EUserRoleImplementation.INTERNAL],
        route: routes.HOW_TOS_HOMEPAGE
    },
    [ERoute.HOW_TO_PLAYLIST]: {
        rules: [EUserRoleImplementation.USER, EUserRoleImplementation.MANAGER, EUserRoleImplementation.INTERNAL],
        route: routes.HOW_TO_PLAYLIST
    },
    [ERoute.SOMETHING_WENT_WRONG]: {
        rules: userManagerPageRuleSet,
        route: routes.SOMETHING_WENT_WRONG
    },
    [ERoute.OUTCOMES]: {
        rules: [
            ...userManagerPageRuleSet,
            {
                role: EUserRoleImplementation.ADMIN,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            },
            {
                role: EUserRoleImplementation.MANAGER,
                permission: [EUserPermission.TENANT_OUTCOME_ASSIGNMENT]
            }
        ],
        route: routes.OUTCOMES
    },

    // PARTNER
    [ERoute.PARTNER_ACCOUNTS]: {
        rules: [
            {
                role: EUserRoleImplementation.PARTNER,
                permission: [EUserPermission.PARTNER]
            }
        ],
        route: routes.PARTNER_ACCOUNTS
    },
    [ERoute.PARTNER_PARTNERS]: {
        rules: [
            {
                role: EUserRoleImplementation.PARTNER,
                permission: [EUserPermission.PARTNER_ADMINISTRATION, EUserPermission.PARTNER],
                operator: 'OR'
            }
        ],
        route: routes.PARTNER_PARTNERS
    },
    [ERoute.PARTNER_RESOURCES]: {
        rules: [
            {
                role: EUserRoleImplementation.PARTNER,
                permission: [EUserPermission.PARTNER]
            }
        ],
        route: routes.PARTNER_RESOURCES
    },
    [ERoute.PARTNER_CONTACT]: {
        rules: [
            {
                role: EUserRoleImplementation.PARTNER,
                permission: [EUserPermission.PARTNER]
            }
        ],
        route: routes.PARTNER_CONTACT
    },
    [ERoute.PARTNER_ACCOUNT_DETAILS]: {
        rules: [
            {
                role: EUserRoleImplementation.PARTNER,
                permission: [EUserPermission.PARTNER]
            }
        ],
        route: routes.PARTNER_ACCOUNT_DETAILS
    }
};

const isUserAllowedToAccess = (
    route: ERoute,
    userRoles: EUserRoleImplementation[],
    userPermissions?: EUserPermission[]
) => {
    if (!userRoles) return false;
    return (
        routeRules[route]?.rules.filter((element: EUserRoleImplementation | IComplexRule) => {
            if (isComplexRule(element)) {
                return (
                    userRoles.findIndex((userRole: EUserRoleImplementation) => {
                        const userRolePresent = userRole === element.role;
                        if (!userRolePresent) return false;
                        const elementOperator = element.operator || 'AND';
                        if (elementOperator === 'AND') {
                            return element.permission.every((v) => userPermissions?.includes(v));
                        } else if (elementOperator === 'OR') {
                            return element.permission.some((v) => userPermissions?.includes(v));
                        }
                        return false;
                    }) !== -1
                );
            } else {
                return userRoles?.includes(element);
            }
        }).length > 0
    );
};

export { routeRules, isUserAllowedToAccess };

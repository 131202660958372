import { createContext, FC, useContext, useEffect, useState, PropsWithChildren, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useGetAllAdminOutcomesQuery,
    usePostBulkAssignOutcome,
    usePostChangeCompanyFocus
} from '../services/OutcomeQueryService';
import {
    EOrderDirection,
    EAdminOutcomeSort,
    IAdminOutcomeSortOrder,
    adminOutcomesCategoryFilterCallback,
    adminOutcomeUserSelectionCategories,
    adminOutcomesOutcomesSelectionCategories,
    adminOutcomesUserDepartmentFilterCallback,
    adminOutcomesUserRegionFilterCallback,
    adminOutcomesUserLicenseTypesFilterCallback,
    appFilterAdminOutcomesCallback
} from './util/filterCategories';
import { EOutcomesStatuses } from '../interfaces/enums/EOutcomesStatuses';
import ClipboardCheckIcon from '../assets/icons/ClipboardCheck';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import { IAdminOutcomeVM } from '../interfaces/views/IAdminOutcomeVM';
import { ITenantAllUserVM } from '../interfaces/views/ITenantAllUserVM';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';
import { EAssignBulkOutcomesType } from '../interfaces/enums/EAssignBulkOutcomesType';
import { useGetTenantLicensedUsersQuery } from '../services/TenantQueryService';
import { filterOutItemsWithEmptyProp } from '../utils/arrayUtils';

interface AdminOutcomesContextProps {
    outcomes: IAdminOutcomeVM[];
    users: ITenantAllUserVM[];
    isOutcomesLoading: boolean;
    isError: boolean;
    refetchData: () => void;
    isFetchedDataProcessed: boolean;
    sortOrder: IAdminOutcomeSortOrder;
    setSortOrder: (sortOrder: IAdminOutcomeSortOrder) => void;
    outcomesStatusOverallInfo: IOutcomeStatusInfo[];
    emptyQuickFilters: () => void;
    outcomesSelectionFilterCategories: IFilterCategories[];
    userSelectionFilterCategories: IFilterCategories[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    onFilterValueChangeUsersTab: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    activeOutcomesStatusOverallFilters: EOutcomesStatuses[];
    changeOutcomeStatusOverallActiveFilters: (key: EOutcomesStatuses) => void;
    activeStepIndex: number;
    changeActiveStepIndexTo: (newStepIndexValue: number) => void;
    selectedOutcomesList: IAdminOutcomeVM[];
    selectedUsersList: ITenantAllUserVM[];
    changeSelectedOutcomesList: (outcomeId: number) => void;
    changeSelectedUsersList: (selectedUserIds: string[]) => void;
    clearAssignment: () => void;
    assignOutcomesCallback: () => Promise<boolean>;
    unassignOutcomesCallback: () => Promise<boolean>;
    isErrorFetchingUserSelectionData: boolean;
    refetchUserSelectionData: () => void;
    isLoadingFetchingUserSelectionData: boolean;
    isPostBulkAssignOutcomeSuccess: boolean;
    isPostBulkAssignOutcomeLoading: boolean;
    isSuccessAssignAction: boolean;
    setSuccessAssignAction: (success: boolean) => void;
    handleCompanyFocusChangeClick: (outcomeId: number, isCompanyFocus: boolean) => void;
    isCompanyFocusChangeLoading: boolean;
}

interface IOutcomeStatusInfo {
    key: EOutcomesStatuses;
    label: string;
    value?: number | string | null;
    icon?: React.ReactNode;
    circleColor?: string;
    isLoading?: boolean;
}

const AdminOutcomesContext = createContext<AdminOutcomesContextProps>({} as AdminOutcomesContextProps);

interface IProps {}

export const AdminOutcomesProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const { data: fetchedOutcomes, isLoading: isOutcomesLoading, isError, refetch } = useGetAllAdminOutcomesQuery();
    const {
        data: fetchedUsers,
        isLoading: isLoadingFetchingUserSelectionData,
        isError: isErrorFetchingUserSelectionData,
        refetch: refetchUserSelectionData
    } = useGetTenantLicensedUsersQuery();
    const [sortOrder, setSortOrder] = useState<IAdminOutcomeSortOrder>({
        sortBy: EAdminOutcomeSort.NAME,
        direction: EOrderDirection.ASC
    });
    const [outcomes, setOutcomes] = useState<IAdminOutcomeVM[]>([]);
    const [users, setUsers] = useState<ITenantAllUserVM[]>([]);
    const [isFetchedDataProcessed, setFetchedDataProcessed] = useState<boolean>(false);
    const [activeOutcomesStatusOverallFilters, setActiveOutcomesStatusOverallFilters] = useState<EOutcomesStatuses[]>(
        []
    );
    const { t } = useTranslation();
    const [outcomesStatusOverallInfo, setOutcomesStatusOverallInfo] = useState<IOutcomeStatusInfo[]>([
        {
            key: EOutcomesStatuses.COMPANY_FOCUS,
            label: t('tooltips.organizationPriority'),
            icon: <ClipboardCheckIcon />,
            value: null,
            isLoading: true
        }
    ]);
    const [userSelectionFilterCategories, setUserSelectionFilterCategories] = useState<IFilterCategories[]>(
        adminOutcomeUserSelectionCategories
    );
    const [outcomesSelectionFilterCategories, setOutcomesSelectionFilterCategories] = useState<IFilterCategories[]>(
        adminOutcomesOutcomesSelectionCategories
    );
    const [filteredOutcomes, setFilteredOutcomes] = useState<IAdminOutcomeVM[]>([]);
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const activeFiltersRef = useRef<string[]>([]);
    const activeOutcomesStatusOverallFiltersRef = useRef<EOutcomesStatuses[]>([]);
    const [selectedOutcomesList, setSelectedOutcomesList] = useState<IAdminOutcomeVM[]>([]);
    const [selectedUsersList, setSelectedUsersList] = useState<ITenantAllUserVM[]>([]);
    const sortOrderRef = useRef({
        sortBy: EAdminOutcomeSort.NAME,
        direction: EOrderDirection.ASC
    });
    const [filteredUsers, setFilteredUsers] = useState<ITenantAllUserVM[]>([]);
    const {
        mutateAsync: mutatePostBulkAssignOutcomeAsync,
        isSuccess: isPostBulkAssignOutcomeSuccess,
        isPending: isPostBulkAssignOutcomeLoading
    } = usePostBulkAssignOutcome();
    const { setToastMessage } = useToastContextStateValue();
    const activeStepIndexRef = useRef<number>(0);
    const [isSuccessAssignAction, setSuccessAssignAction] = useState<boolean>(false);
    const { mutateAsync: mutatePostChangeCompanyFocusAsync, isPending: isCompanyFocusChangeLoading } =
        usePostChangeCompanyFocus();

    useEffect(() => {
        activeStepIndexRef.current = activeStepIndex;
    }, [activeStepIndex]);

    useEffect(() => {
        sortOrderRef.current = sortOrder;
    }, [sortOrder]);

    const sortByOrder = (outcomes: IAdminOutcomeVM[]) => {
        let newOutcomes = [...outcomes];
        const sortOrder = sortOrderRef.current;
        switch (sortOrder.sortBy) {
            case EAdminOutcomeSort.NAME:
                newOutcomes = outcomes.sort((a, b) => {
                    if (sortOrder.direction === EOrderDirection.ASC)
                        return ('' + a.outcome.title).localeCompare(b.outcome.title);
                    return ('' + b.outcome.title).localeCompare(a.outcome.title);
                });
                return newOutcomes;
            case EAdminOutcomeSort.NUMBER_OF_USERS:
                newOutcomes = outcomes.sort((a, b) => {
                    if (sortOrder.direction === EOrderDirection.ASC)
                        return a.assignedUserCount > b.assignedUserCount ? 1 : -1;
                    return a.assignedUserCount < b.assignedUserCount ? 1 : -1;
                });
                return newOutcomes;
            default:
                return newOutcomes;
        }
    };

    useEffect(() => {
        activeOutcomesStatusOverallFiltersRef.current = activeOutcomesStatusOverallFilters;
    }, [activeOutcomesStatusOverallFilters]);

    useEffect(() => {
        onAnythingChange(outcomes, true);
    }, [activeOutcomesStatusOverallFilters, outcomesSelectionFilterCategories, sortOrder, outcomes]);

    useEffect(() => {
        onAnythingChangeUsersTab(users, true);
    }, [userSelectionFilterCategories, users]);

    const calculateSkillStatusOverallInfo = (filterOutcomes?: IAdminOutcomeVM[]) => {
        const outcomesToFilter = filterOutcomes || outcomes;
        if (outcomesToFilter) {
            let numberOfCompanyFocusOutcomes: number = 0;
            numberOfCompanyFocusOutcomes = outcomesToFilter.filter((outcome) => outcome.isFavorite).length;
            setOutcomesStatusOverallInfo((currentSkillsStatusOverall) => {
                let newOutcomesStatusOverall = [...currentSkillsStatusOverall];
                newOutcomesStatusOverall = newOutcomesStatusOverall.map((sso) => {
                    switch (sso.key) {
                        case EOutcomesStatuses.COMPANY_FOCUS:
                            sso.value = numberOfCompanyFocusOutcomes;
                            break;
                    }
                    return {
                        ...sso,
                        isLoading: false
                    };
                });
                return newOutcomesStatusOverall;
            });
        }
    };

    useEffect(() => {
        if (fetchedUsers) {
            const users = fetchedUsers;
            setFilteredUsers(users);

            const departments = new Set<string>(
                filterOutItemsWithEmptyProp(users, (user) => user.department).map((user) => user.department)
            );
            const licenseTypes = new Set<string>(
                filterOutItemsWithEmptyProp(users, (user) => user.sourceLicenseType).map(
                    (user) => user.sourceLicenseType
                )
            );
            const regions = new Set<string>([...users.map((user) => user.country)]);

            userSelectionFilterCategories.forEach((filterCategory) => {
                if (filterCategory.name === 'All Departments') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: adminOutcomesUserDepartmentFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.name === 'All License Types') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    licenseTypes.forEach((licenseType) => {
                        categoryValues.push({
                            key: licenseType,
                            name: licenseType,
                            callback: adminOutcomesUserLicenseTypesFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.name === 'All Regions') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    regions.forEach((region) => {
                        categoryValues.push({
                            key: region,
                            name: region,
                            callback: adminOutcomesUserRegionFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setUserSelectionFilterCategories([...userSelectionFilterCategories]);
        }
    }, [users]);

    useEffect(() => {
        if (fetchedOutcomes) {
            const outcomes = fetchedOutcomes;
            setFilteredOutcomes(outcomes);
            calculateSkillStatusOverallInfo();
            const apps = new Set<string>();
            outcomes.forEach((outcome) => {
                outcome.outcome.skills.forEach((skill) => {
                    skill.apps.forEach((app) => {
                        apps.add(app.name);
                    });
                });
            });
            const categories = new Set<string>();
            outcomes.forEach((outcome) => {
                outcome.outcome.categories.forEach((category) => {
                    categories.add(category);
                });
            });

            outcomesSelectionFilterCategories.forEach((filterCategory) => {
                if (filterCategory.name === 'App') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    apps.forEach((app) => {
                        categoryValues.push({
                            key: app,
                            name: app,
                            callback: appFilterAdminOutcomesCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.name === 'Category') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    categories.forEach((category) => {
                        categoryValues.push({
                            key: category,
                            name: category,
                            callback: adminOutcomesCategoryFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setOutcomesSelectionFilterCategories([...outcomesSelectionFilterCategories]);
        }
    }, [outcomes]);

    const changeOutcomeStatusOverallActiveFilters = useCallback(
        (key: EOutcomesStatuses) => {
            setActiveOutcomesStatusOverallFilters((activeStatusList) => {
                if (activeStatusList.includes(key)) {
                    return [];
                }
                return [key];
            });
        },
        [filteredOutcomes, activeOutcomesStatusOverallFilters]
    );

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        newActiveFilters?: string[],
        dontRunAnythingChange?: boolean,
        outcomesToFilter?: IAdminOutcomeVM[] // use this array for filtering if not undefined, else use current filteredSkills from this context
    ) => {
        let newFilteredOutcomes: IAdminOutcomeVM[] = [];
        if (outcomesToFilter) newFilteredOutcomes = outcomesToFilter;
        else newFilteredOutcomes = [...outcomes];
        let currentActiveFilters = activeFiltersRef.current;
        if (newActiveFilters) {
            currentActiveFilters = newActiveFilters;
            activeFiltersRef.current = newActiveFilters;
        }
        filterCategories.forEach((filterCategory) => {
            filterCategory.values!.forEach((filterCategoryValue) => {
                if (filterCategoryValue.callback && currentActiveFilters.includes(filterCategoryValue.key)) {
                    newFilteredOutcomes = newFilteredOutcomes.filter((outcome) => {
                        if (filterCategoryValue.callback) {
                            const isValid = filterCategoryValue.callback(
                                outcome,
                                filterCategoryValue.name,
                                filterCategoryValue.key
                            );
                            return isValid;
                        }
                        return false;
                    });
                }
            });
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredOutcomes, true);
        return newFilteredOutcomes;
    };

    const onFilterValueChangeUsersTab = (
        filterCategories: IFilterCategories[],
        newActiveFilters?: string[],
        dontRunAnythingChange?: boolean,
        usersToFilter?: ITenantAllUserVM[] // use this array for filtering if not undefined, else use current filteredSkills from this context
    ) => {
        let newFilteredUsers: ITenantAllUserVM[] = [];
        if (usersToFilter) newFilteredUsers = usersToFilter;
        else newFilteredUsers = [...users];
        let currentActiveFilters = activeFiltersRef.current;
        if (newActiveFilters) {
            currentActiveFilters = newActiveFilters;
            activeFiltersRef.current = newActiveFilters;
        }
        filterCategories.forEach((filterCategory) => {
            filterCategory.values!.forEach((filterCategoryValue) => {
                if (filterCategoryValue.callback && currentActiveFilters.includes(filterCategoryValue.key)) {
                    newFilteredUsers = newFilteredUsers.filter((outcome) => {
                        if (filterCategoryValue.callback) {
                            const isValid = filterCategoryValue.callback(
                                outcome,
                                filterCategoryValue.name,
                                filterCategoryValue.key
                            );
                            return isValid;
                        }
                        return false;
                    });
                }
            });
        });
        if (!dontRunAnythingChange) onAnythingChangeUsersTab(newFilteredUsers, true);
        return newFilteredUsers;
    };

    const filterOutcomesBySkillStatusOverall = (outcomes: IAdminOutcomeVM[]) => {
        if (outcomes) {
            let newOutcomes = [...outcomes];

            activeOutcomesStatusOverallFiltersRef.current.forEach((statusFilter) => {
                switch (statusFilter) {
                    case EOutcomesStatuses.COMPANY_FOCUS:
                        newOutcomes = newOutcomes.filter((outcome) => outcome.isFavorite);
                        break;
                }
            });
            return newOutcomes;
        }
        return outcomes;
    };

    const onAnythingChange = (outcomesNeedToBeFiltered: IAdminOutcomeVM[], runSearchText?: boolean) => {
        let newFilteredOutcomes = [...outcomesNeedToBeFiltered];
        newFilteredOutcomes = filterOutcomesBySkillStatusOverall(newFilteredOutcomes);
        if (runSearchText) {
            newFilteredOutcomes = sortByOrder(newFilteredOutcomes);
            newFilteredOutcomes = onFilterValueChange(
                outcomesSelectionFilterCategories,
                undefined,
                true,
                newFilteredOutcomes
            );
            setFilteredOutcomes(newFilteredOutcomes);
        } else {
            newFilteredOutcomes = sortByOrder(newFilteredOutcomes);
            newFilteredOutcomes = onFilterValueChange(
                outcomesSelectionFilterCategories,
                undefined,
                true,
                newFilteredOutcomes
            );
            setFilteredOutcomes(newFilteredOutcomes);
        }
        return newFilteredOutcomes;
    };

    const onAnythingChangeUsersTab = (usersNeedToBeFiltered: ITenantAllUserVM[], runSearchText?: boolean) => {
        let newFilteredUsers = [...usersNeedToBeFiltered];
        if (runSearchText) {
            newFilteredUsers = onFilterValueChangeUsersTab(
                userSelectionFilterCategories,
                undefined,
                true,
                newFilteredUsers
            );
            setFilteredUsers(newFilteredUsers);
        } else {
            newFilteredUsers = onFilterValueChangeUsersTab(
                userSelectionFilterCategories,
                undefined,
                true,
                newFilteredUsers
            );
            setFilteredUsers(newFilteredUsers);
        }
        return newFilteredUsers;
    };

    const emptyQuickFilters = useCallback(() => {
        setActiveOutcomesStatusOverallFilters([]);
    }, []);

    const changeActiveStepIndexTo = useCallback((newStepIndex: number) => {
        setActiveStepIndex(newStepIndex);
    }, []);

    const changeSelectedOutcomesList = useCallback(
        (outcomeId: number) => {
            const alreadyAdded = selectedOutcomesList.findIndex((adminOutcome) => {
                return adminOutcome.outcome.id === outcomeId;
            });

            if (alreadyAdded !== -1) {
                setSelectedOutcomesList((outcomes) => {
                    const newSelectedOutcomes = [...outcomes];
                    newSelectedOutcomes.splice(alreadyAdded, 1);
                    return newSelectedOutcomes;
                });
            } else {
                const outcome = outcomes.find((adminOutcome) => adminOutcome.outcome.id === outcomeId);
                if (outcome)
                    setSelectedOutcomesList((selectedOutcomesList) => {
                        return [...selectedOutcomesList, outcome];
                    });
            }
        },
        [outcomes, selectedOutcomesList]
    );

    const changeSelectedUsersList = useCallback(
        (selectedUserIds: string[]) => {
            const newSelectedUsersList: ITenantAllUserVM[] = [];
            selectedUserIds.forEach((userId) => {
                const user = users.find((user) => user.id === userId);
                if (user) newSelectedUsersList.push(user);
            });
            setSelectedUsersList(newSelectedUsersList);
        },
        [users, selectedUsersList]
    );

    useEffect(() => {
        if (fetchedOutcomes) {
            setOutcomes(fetchedOutcomes);
            setFetchedDataProcessed(true);
        }
    }, [fetchedOutcomes]);

    useEffect(() => {
        if (fetchedUsers) {
            setUsers(fetchedUsers);
        }
    }, [fetchedUsers]);

    const clearAssignment = useCallback(() => {
        setSelectedOutcomesList([]);
        setSelectedUsersList([]);
        setActiveStepIndex(0);
    }, []);

    const assignOutcomesCallback = useCallback(async () => {
        try {
            await mutatePostBulkAssignOutcomeAsync({
                assignmentType: EAssignBulkOutcomesType.ASSIGN,
                outcomeIds: selectedOutcomesList.map((outcome) => outcome.outcome.id),
                targetUserIds: selectedUsersList.map((user) => user.id)
            });
            setToastMessage({
                isOpen: true,
                message: t('success.adminOutcomes.assignOutcomes'),
                severity: EToastSeverity.SUCCESS
            });
            return true;
        } catch (e) {
            console.error(e);
            setToastMessage({
                isOpen: true,
                message: t('errors.adminOutcomes.assignOutcomes'),
                severity: EToastSeverity.ERROR
            });
            return false;
        }
    }, [selectedOutcomesList, selectedUsersList]);

    const unassignOutcomesCallback = useCallback(async () => {
        try {
            await mutatePostBulkAssignOutcomeAsync({
                assignmentType: EAssignBulkOutcomesType.UNASSIGN,
                outcomeIds: selectedOutcomesList.map((outcome) => outcome.outcome.id),
                targetUserIds: selectedUsersList.map((user) => user.id)
            });
            setToastMessage({
                isOpen: true,
                message: t('success.adminOutcomes.unassignOutcomes'),
                severity: EToastSeverity.SUCCESS
            });
            return true;
        } catch (e) {
            console.error(e);
            setToastMessage({
                isOpen: true,
                message: t('errors.adminOutcomes.unassignOutcomes'),
                severity: EToastSeverity.ERROR
            });
            return false;
        }
    }, [selectedOutcomesList, selectedUsersList]);

    const handleCompanyFocusChangeClickCallback = useCallback(async (outcomeId: number, isCompanyFocus: boolean) => {
        try {
            await mutatePostChangeCompanyFocusAsync({
                companyFocus: isCompanyFocus,
                outcomeId
            });
            await refetch();
            setToastMessage({
                isOpen: true,
                message: isCompanyFocus
                    ? t('success.adminOutcomes.setCompanyFocusSuccess')
                    : t('success.adminOutcomes.removeCompanyFocusSuccess'),
                severity: EToastSeverity.SUCCESS
            });
        } catch (err) {
            console.error(err);
            setToastMessage({
                isOpen: true,
                message: isCompanyFocus
                    ? t('success.adminOutcomes.setCompanyFocusError')
                    : t('success.adminOutcomes.removeCompanyFocusError'),
                severity: EToastSeverity.ERROR
            });
        }
    }, []);

    const contextState: AdminOutcomesContextProps = {
        outcomes: filteredOutcomes,
        users: filteredUsers,
        isOutcomesLoading,
        isError,
        refetchData: refetch,
        isFetchedDataProcessed,
        sortOrder,
        setSortOrder,
        outcomesStatusOverallInfo,
        emptyQuickFilters,
        outcomesSelectionFilterCategories,
        userSelectionFilterCategories,
        onFilterValueChange,
        activeOutcomesStatusOverallFilters,
        changeOutcomeStatusOverallActiveFilters,
        activeStepIndex,
        changeActiveStepIndexTo,
        selectedOutcomesList,
        selectedUsersList,
        changeSelectedOutcomesList,
        changeSelectedUsersList,
        clearAssignment,
        onFilterValueChangeUsersTab,
        assignOutcomesCallback,
        unassignOutcomesCallback,
        isErrorFetchingUserSelectionData,
        isLoadingFetchingUserSelectionData,
        refetchUserSelectionData,
        isPostBulkAssignOutcomeSuccess,
        isPostBulkAssignOutcomeLoading,
        isSuccessAssignAction,
        setSuccessAssignAction,
        handleCompanyFocusChangeClick: handleCompanyFocusChangeClickCallback,
        isCompanyFocusChangeLoading
    };

    return <AdminOutcomesContext.Provider value={contextState}>{children}</AdminOutcomesContext.Provider>;
};

export const useAdminOutcomesStateValue: () => AdminOutcomesContextProps = () => useContext(AdminOutcomesContext);

export default AdminOutcomesContext;

import { Box, Checkbox } from '@mui/material';
import { default as MuiTableHead } from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Order } from '../../../../hooks/useTable';
import { StyledHeaderTableCell, StyledMultipleCheckboxHeaderTableCell } from '../../../../css/CommonComponents';
import { ITableHeadCell } from '../../../../interfaces/ITableHeaderCell';
import {
    HeaderOverviewTableCell,
    HeaderOverviewTableCellTypography,
    HeaderOverviewTableCellWithLineTypography
} from './Style';
import React from 'react';
import { StyledTableSortLabel } from '../Style';

interface EnhancedTableProps<Data> {
    numSelected: number;
    onRequestSort: (property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: ITableHeadCell<Data>[];
    isMultipleSelectionAvailable?: boolean;
    isDisabled: boolean;
    headerOverviewConfig?: {
        colSpan: number;
        label: string;
    }[];
    headerCellStyle?: React.CSSProperties;
}

function TableHead<Data>(props: EnhancedTableProps<Data>) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        headCells,
        isMultipleSelectionAvailable = false,
        isDisabled = false,
        headerOverviewConfig,
        headerCellStyle
    } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(property);
    };

    return (
        <MuiTableHead>
            {headerOverviewConfig && (
                <TableRow>
                    {headerOverviewConfig.map((headerItem, index) => {
                        return (
                            <HeaderOverviewTableCell key={index} colSpan={headerItem.colSpan}>
                                {headerItem.label && headerItem.label !== '' ? (
                                    <HeaderOverviewTableCellWithLineTypography variant='overline'>
                                        {headerItem.label}
                                    </HeaderOverviewTableCellWithLineTypography>
                                ) : (
                                    <HeaderOverviewTableCellTypography variant='overline'>
                                        {headerItem.label}
                                    </HeaderOverviewTableCellTypography>
                                )}
                            </HeaderOverviewTableCell>
                        );
                    })}
                </TableRow>
            )}
            <TableRow>
                {isMultipleSelectionAvailable && (
                    <StyledMultipleCheckboxHeaderTableCell aria-label='Select all items' padding='checkbox'>
                        <Checkbox
                            color='primary'
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={isDisabled ? undefined : onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all items'
                            }}
                        />
                    </StyledMultipleCheckboxHeaderTableCell>
                )}
                {headCells.map((headCell) => (
                    <StyledHeaderTableCell
                        //@ts-ignore
                        key={headCell.id}
                        align={headCell?.align || 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headerCellStyle}
                        aria-label={headCell.id}
                    >
                        <StyledTableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={isDisabled || headCell.disableSort ? undefined : createSortHandler(headCell.id)}
                            style={{
                                width: headCell?.width,
                                minWidth: headCell?.minWidth,
                                maxWidth: headCell?.maxWidth,
                                display: 'flex',
                                justifyContent: headCell?.align === 'center' ? 'center' : 'inherit',
                                paddingLeft:
                                    headCell.customRender && headCell?.align === 'center' && !headCell.disablePadding
                                        ? 24
                                        : 0,
                                cursor: isDisabled || headCell.disableSort ? 'default' : 'pointer'
                            }}
                            hideSortIcon={!!headCell.disableSort}
                            isNotClickable={!!(isDisabled || headCell.disableSort)}
                            tabIndex={0}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component='span' style={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </StyledTableSortLabel>
                    </StyledHeaderTableCell>
                ))}
            </TableRow>
        </MuiTableHead>
    );
}

export default TableHead;

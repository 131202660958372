import { useEffect } from 'react';
import { Outlet, useSearchParams, useNavigate, useMatch } from 'react-router-dom';
import { useTabsStateValue, useWizardConfig } from 'nulia-ui';
import { ShepherdTour } from 'react-shepherd';
import PageHeader from './components/page-header/PageHeader';

const MainLayoutTeams = () => {
    const { changeSearchText, searchText } = useTabsStateValue();
    const { config, tourOptions } = useWizardConfig();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    console.log("Search parameters:", searchParams);

    useEffect(() => {
        const pathToNavigateTo = searchParams.get('path');

        const match1 = useMatch('/v3/?path=skills');
        const match2 = useMatch('/?path=skills');
        const match3 = useMatch('/skills');

        console.log("Matches:", match1, match2, match3);
        console.log("Path to navigate:", pathToNavigateTo);

        if (pathToNavigateTo != null) {
            navigate(pathToNavigateTo, {});
        }
    }, [searchParams]);

    return (
        <>
            {/* @ts-ignore */}
            <ShepherdTour steps={config} tourOptions={tourOptions}>
                <PageHeader onSearchInputValueChange={changeSearchText} searchInputValue={searchText} />
                <Outlet />
            </ShepherdTour>
        </>
    );
};

export default MainLayoutTeams;

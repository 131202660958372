import { Duration, format, intervalToDuration } from 'date-fns';
import { EFormatDate } from '../interfaces/enums/EFormatDate';

const fullYearFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
};
const dayAndMonthFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric'
};
const monthAndYearFormat: Intl.DateTimeFormatOptions = {
    month: 'numeric',
    year: 'numeric'
};

const dayAndMonthStringAndYearFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
};

export const formatISOString: (isoString?: string | null, dateFormat?: string) => string = (
    isoString,
    dateFormat = 'LLLL dd, yyyy'
) => {
    if (!isoString) return '';
    return format(new Date(isoString), dateFormat);
};

export const formatDate: (date?: Date | null, dateFormat?: string) => string = (date, dateFormat = 'MMM do, yyyy') => {
    if (!date) return '';
    return format(date, dateFormat);
};

export const timeBetweenTwoDates: (props: { start: Date; end: Date }) => string = ({ start, end }) => {
    const intervalObj: Duration = intervalToDuration({ start, end });
    if (intervalObj.years) return `${intervalObj.years} years ago`;
    if (intervalObj.months) return `${intervalObj.months} months ago`;
    if (intervalObj.days) return `${intervalObj.days} days ago`;
    if (intervalObj.hours) return `${intervalObj.hours} hours ago`;
    if (intervalObj.minutes) return `${intervalObj.minutes} minutes ago`;
    if (intervalObj.seconds) return `${intervalObj.seconds} seconds ago`;
    return '';
};

export const formatLocalDate: (date: Date | null, formatDate?: EFormatDate) => string = (
    date,
    formatDate = EFormatDate.FULL_YEAR
) => {
    if (!date) return '';
    let chosenFormat: Intl.DateTimeFormatOptions = fullYearFormat;
    switch (formatDate) {
        case EFormatDate.FULL_YEAR:
            chosenFormat = fullYearFormat;
            break;
        case EFormatDate.MONTH_AND_YEAR:
            chosenFormat = monthAndYearFormat;
            break;
        case EFormatDate.DAY_AND_MONTH:
            chosenFormat = dayAndMonthFormat;
            break;
        case EFormatDate.DAY_AND_MONTH_SHORT_STRING_AND_YEAR:
            chosenFormat = dayAndMonthStringAndYearFormat;
            break;
    }
    return date.toLocaleDateString(undefined, chosenFormat);
};

import { createContext, FC, useContext, PropsWithChildren, useState } from 'react';
import {
    useGetAccountDetailsQuery,
    useGetCustomerAdministratorsQuery,
    useGetCustomerSettingsQuery
} from '../services/AccountsQueryService';
// import { EAccountStatus } from '../interfaces/enums/EAccountStatus';
// import { EAccountType } from '../interfaces/enums/EAccountType';
import { IAccountDetailsVM } from '../interfaces/views/IAccountDetailsVM';
import { IActivityVM } from '../interfaces/views/IActivityVM';
import { EActivityType } from '../interfaces/enums/EActivityType';
import { useParams } from 'react-router';
import { IAdministratorVM } from '../interfaces/views/IAdministratorVM';
import { ICustomerSettingsVM } from '../interfaces/views/ICustomerSettingsVM';

export interface IAccountDetailsContext {
    data?: IAccountDetailsVM[];
    activityData?: IActivityVM[];
    isAccountDetailsDataLoading: boolean;
    isAccountDetailsDataFetchingError: boolean;
    isActivityDataLoading: boolean;
    isActivityDataFetchingError: boolean;
    administratorsList?: IAdministratorVM[];
    customerId?: string;
    isAdministratorsLoading: boolean;
    isAdministratorsError: boolean;
    refetchAdministratorsApiCall: () => void;
    settingsData?: ICustomerSettingsVM;
    isSettingsLoading: boolean;
    isSettingsError: boolean;
    refetchSettingsApiCall: () => void;
}

export const AccountDetailsContext = createContext<IAccountDetailsContext>({} as IAccountDetailsContext);

interface IProps {}

export const AccountDetailsProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const [activityData] = useState([
        {
            id: '1',
            timestamp: 'Sep 24, 2024 6:02 PM',
            activityBy: 'John Doe',
            activityType: EActivityType.CHANGE_NAME,
            message: 'Name changed from "ACustomer Name" to "ACustomer Name"'
        },
        {
            id: '2',
            timestamp: 'Sep 14, 2024 6:12 PM',
            activityBy: 'Nick Pope',
            activityType: EActivityType.REMOVE_ADMIN,
            message: '"dusan.sancanin@nulia.com" Removed as a Nulia Admin'
        }
    ]);
    const {
        data: fetchedAccountsData,
        isLoading: isAccountDetailsDataLoading,
        isError: isAccountDetailsDataFetchingError
    } = useGetAccountDetailsQuery();
    const params = useParams<{ id: string }>();
    const {
        data: fetchedCustomerAdministratorsData,
        isLoading: isAdministratorsLoading,
        isError: isAdministratorsError,
        refetch: refetchAdministratorsApiCall
    } = useGetCustomerAdministratorsQuery(params?.id);
    const {
        data: fetchedCustomerSettingsData,
        isLoading: isSettingsLoading,
        isError: isSettingsError,
        refetch: refetchSettingsApiCall
    } = useGetCustomerSettingsQuery(params?.id);

    const accountDetailsState: IAccountDetailsContext = {
        data: fetchedAccountsData || [],
        isAccountDetailsDataLoading,
        isAccountDetailsDataFetchingError,
        activityData: activityData,
        isActivityDataFetchingError: false,
        isActivityDataLoading: false,
        administratorsList: fetchedCustomerAdministratorsData,
        customerId: params.id,
        refetchAdministratorsApiCall,
        isAdministratorsLoading,
        isAdministratorsError,
        settingsData: fetchedCustomerSettingsData,
        isSettingsError,
        isSettingsLoading,
        refetchSettingsApiCall
    };

    return <AccountDetailsContext.Provider value={accountDetailsState}>{children}</AccountDetailsContext.Provider>;
};

export const useAccountDetailsStateValue: () => IAccountDetailsContext = () => useContext(AccountDetailsContext);
